import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/emcasa/ui/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props } from 'docz';
import GoogleMapsAutoComplete from '@emcasa/places-autocomplete/GoogleMapsAutoComplete/index.jsx';
import PlaceAutoComplete from '@emcasa/places-autocomplete/PlaceAutoComplete/index.jsx';
import AddressAutoComplete from '@emcasa/places-autocomplete/AddressAutoComplete/index.jsx';
import { GOOGLE_MAPS_API_KEY } from '../config';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Playground = makeShortcode("Playground");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "googlemapsautocomplete"
    }}>{`GoogleMapsAutoComplete`}</h1>
    <Props of={GoogleMapsAutoComplete} mdxType="Props" />
    <p>{`Select predictions from google places autocomplete api.`}</p>
    <Playground __position={1} __code={'<GoogleMapsAutoComplete\n  options={{ mode: \'cors\' }}\n  // mock /autocomplete api\n  apiUrl={() => `https://api.myjson.com/bins/8ur2v`}\n  onSelect={prediction => console.log({ prediction })}\n/>'} __scope={{
      props,
      DefaultLayout,
      Props,
      GoogleMapsAutoComplete,
      PlaceAutoComplete,
      AddressAutoComplete,
      GOOGLE_MAPS_API_KEY
    }} style={{
      minHeight: "250px"
    }} mdxType="Playground">
  <GoogleMapsAutoComplete options={{
        mode: 'cors'
      }} // mock /autocomplete api
      apiUrl={() => `https://api.myjson.com/bins/8ur2v`} onSelect={prediction => console.log({
        prediction
      })} mdxType="GoogleMapsAutoComplete" />
    </Playground>
    <h1 {...{
      "id": "placeautocomplete"
    }}>{`PlaceAutoComplete`}</h1>
    <p>{`Google place autocomplete dropdown with additional data from place details api.`}</p>
    <Playground __position={2} __code={'<PlaceAutoComplete\n  options={{ mode: \'cors\' }}\n  apiUrl={({ endpoint }) =>\n    endpoint === \'details\'\n      ? // mock /autocomplete endpoint\n        `https://api.myjson.com/bins/tiphj`\n      : // mock /details endpoint\n        `https://api.myjson.com/bins/8ur2v`\n  }\n  onSelect={(prediction, place) => console.log({ prediction, place })}\n/>'} __scope={{
      props,
      DefaultLayout,
      Props,
      GoogleMapsAutoComplete,
      PlaceAutoComplete,
      AddressAutoComplete,
      GOOGLE_MAPS_API_KEY
    }} style={{
      minHeight: "250px"
    }} mdxType="Playground">
  <PlaceAutoComplete options={{
        mode: 'cors'
      }} apiUrl={({
        endpoint
      }) => endpoint === 'details' // mock /autocomplete endpoint
      ? `https://api.myjson.com/bins/tiphj` // mock /details endpoint
      : `https://api.myjson.com/bins/8ur2v`} onSelect={(prediction, place) => console.log({
        prediction,
        place
      })} mdxType="PlaceAutoComplete" />
    </Playground>
    <h1 {...{
      "id": "addressautocomplete"
    }}>{`AddressAutoComplete`}</h1>
    <p>{`Validates street number and postal code in addition to fetching place details.`}</p>
    <Playground __position={3} __code={'<AddressAutoComplete\n  options={{ mode: \'cors\' }}\n  apiUrl={({ endpoint, value }) =>\n    endpoint === PlaceAutoComplete.API_ENDPOINT\n      ? // mock /autocomplete endpoint\n        `http://myjson.com/tiphj`\n      : // mock /details endpoint\n        `https://api.myjson.com/bins/8ur2v`\n  }\n  onSelect={(prediction, place, input) =>\n    console.log({ prediction, place, input })\n  }\n/>'} __scope={{
      props,
      DefaultLayout,
      Props,
      GoogleMapsAutoComplete,
      PlaceAutoComplete,
      AddressAutoComplete,
      GOOGLE_MAPS_API_KEY
    }} style={{
      minHeight: "250px"
    }} mdxType="Playground">
  <AddressAutoComplete options={{
        mode: 'cors'
      }} apiUrl={({
        endpoint,
        value
      }) => endpoint === PlaceAutoComplete.API_ENDPOINT // mock /autocomplete endpoint
      ? `http://myjson.com/tiphj` // mock /details endpoint
      : `https://api.myjson.com/bins/8ur2v`} onSelect={(prediction, place, input) => console.log({
        prediction,
        place,
        input
      })} mdxType="AddressAutoComplete" />
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      